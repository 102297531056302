export const createAction = (action) => {
  const conf = {
    ACTION: action,
    REQUEST: `${action}_REQUEST`,
    SUCCESS: `${action}_SUCCESS`,
    ERROR: `${action}_ERROR`
  };
  conf.requestAction = (payload) => ({
    type: conf.REQUEST,
    payload: payload ? payload : {}
  })
  conf.successAction = (payload) => ({
    type: conf.SUCCESS,
    payload: payload ? payload : {}
  })
  conf.errorAction = (error) => ({
    type: conf.ERROR,
    payload: { error }
  })
  return conf
};

/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* THEME */
export const THEME_GET = "THEME_GET";
export const THEME_SET = "THEME_SET";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_FACEBOOK = "LOGIN_FACEBOOK";
export const LOGIN_FIREBASE = "LOGIN_FIREBASE";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const AUTH_RESET = createAction('AUTH_RESET')
export const ROLE_REQUEST = createAction('ROLE_REQUEST')

export const VERSION_CHECK = createAction('VERSION_CHECK')

export const FB_TOKEN_CHANGED = createAction('FB_TOKEN_CHANGED')

export const INITIAL_ROUTE = "INITIAL_ROUTE";

export const PUSH_REGISTER = "PUSH_REGISTER";
export const PUSH_REGISTER_STORE = "PUSH_REGISTER_STORE";
export const PUSH_REGISTER_SUCCESS = "PUSH_REGISTER_SUCCESS";
export const PUSH_REGISTER_ERROR = "PUSH_REGISTER_ERROR";

export const PM2_PROCESSES = createAction('PM2_PROCESSES')

export const GENERIC_API_ERROR = "GENERIC_API_ERROR";

/* TOWERS */
export const SITES_SET_SORT = createAction('SITES_SET_SORT');
export const SITES_SET_VIEW = createAction('SITES_SET_VIEW');
export const SITES_SET_SEARCH = createAction('SITES_SET_SEARCH');
export const SITES_SET_FILTERS = createAction('SITES_SET_FILTERS');
export const SITES_CRUD_MODE = createAction('SITES_CRUD_MODE');
export const SITES_FETCH = createAction('SITES_FETCH');
export const SITES_CRUD_SUBMIT = createAction('SITES_CRUD_SUBMIT');

export const CLIENTS_SET_SORT = createAction('CLIENTS_SET_SORT');
export const CLIENTS_SET_VIEW = createAction('CLIENTS_SET_VIEW');
export const CLIENTS_SET_SEARCH = createAction('CLIENTS_SET_SEARCH');
export const CLIENTS_SET_FILTERS = createAction('CLIENTS_SET_FILTERS');
export const CLIENTS_CRUD_MODE = createAction('CLIENTS_CRUD_MODE');
export const CLIENTS_FETCH = createAction('CLIENTS_FETCH');
export const CLIENTS_CRUD_SUBMIT = createAction('CLIENTS_CRUD_SUBMIT');

export const INCIDENTS_SET_SORT = createAction('INCIDENTS_SET_SORT');
export const INCIDENTS_SET_VIEW = createAction('INCIDENTS_SET_VIEW');
export const INCIDENTS_SET_SEARCH = createAction('INCIDENTS_SET_SEARCH');
export const INCIDENTS_SET_FILTERS = createAction('INCIDENTS_SET_FILTERS');
export const INCIDENTS_CRUD_MODE = createAction('INCIDENTS_CRUD_MODE');
export const INCIDENTS_FETCH = createAction('INCIDENTS_FETCH');
export const INCIDENTS_CRUD_SUBMIT = createAction('INCIDENTS_CRUD_SUBMIT');
export const INCIDENTS_SUMMARY_FETCH = createAction('INCIDENTS_SUMMARY_FETCH');
export const INCIDENTS_STATS_FETCH = createAction('INCIDENTS_STATS_FETCH');
export const OVERVIEW_SET_DATE = createAction('OVERVIEW_SET_DATE');

export const TASKS_SET_SORT = createAction('TASKS_SET_SORT');
export const TASKS_SET_VIEW = createAction('TASKS_SET_VIEW');
export const TASKS_SET_SEARCH = createAction('TASKS_SET_SEARCH');
export const TASKS_SET_FILTERS = createAction('TASKS_SET_FILTERS');
export const TASKS_CRUD_MODE = createAction('TASKS_CRUD_MODE');
export const TASKS_FETCH = createAction('TASKS_FETCH');
export const TASKS_MINE_FETCH = createAction('TASKS_MINE_FETCH');
export const TASKS_CRUD_SUBMIT = createAction('TASKS_CRUD_SUBMIT');
export const TASKS_UPDATE_STATUS = createAction('TASKS_UPDATE_STATUS');
export const TASKS_UPDATE_PRIORITY = createAction('TASKS_UPDATE_PRIORITY');
export const TASKS_FILE_DELETE = createAction('TASKS_FILE_DELETE');

export const FILE_UPLOAD_REQUEST = createAction('FILE_UPLOAD_REQUEST');

export const TASKS_BOARD_OPEN_DIALOG = createAction('TASKS_BOARD_OPEN_DIALOG');
export const TASKS_BOARD_CLOSE_DIALOG = createAction('TASKS_BOARD_CLOSE_DIALOG');
export const TASKS_BOARD_UPDATE_DIALOG_VIEW = createAction('TASKS_BOARD_UPDATE_DIALOG_VIEW');
export const TASKS_BOARD_SET_SELECTED_TASKS_ID = createAction('TASKS_BOARD_SET_SELECTED_TASKS_ID');
export const TASKS_BOARD_SET_SELECTED_LOGS_ID = createAction('TASKS_BOARD_SET_SELECTED_LOGS_ID');
export const TASKS_BOARD_SET_SELECTED_BOARD = createAction('TASKS_BOARD_SET_SELECTED_BOARD');
export const TASKS_BOARD_SET_SELECTED_TAB = createAction('TASKS_BOARD_SET_SELECTED_TAB');
export const TASKS_BOARD_SET_INCIDENT = createAction('TASKS_BOARD_SET_INCIDENT');
export const TASKS_BOARD_UPDATE_ORDERED = createAction('TASKS_BOARD_UPDATE_ORDERED');
export const TASKS_BOARD_UPDATE_COLUMNS = createAction('TASKS_BOARD_UPDATE_COLUMNS');
export const TASKS_BOARD_UPDATE_BOARD_MEMBERS = createAction('TASKS_BOARD_UPDATE_BOARD_MEMBERS');
export const TASKS_BOARD_BOARDS_FETCH = createAction('TASKS_BOARD_BOARDS_FETCH');
export const TASKS_BOARD_MEMBERS_FETCH = createAction('TASKS_BOARD_MEMBERS_FETCH');

export const LOGS_SET_SORT = createAction('LOGS_SET_SORT');
export const LOGS_SET_VIEW = createAction('LOGS_SET_VIEW');
export const LOGS_SET_SEARCH = createAction('LOGS_SET_SEARCH');
export const LOGS_SET_FILTERS = createAction('LOGS_SET_FILTERS');
export const LOGS_CRUD_MODE = createAction('LOGS_CRUD_MODE');
export const LOGS_FETCH = createAction('LOGS_FETCH');
export const LOGS_TIMELINE = createAction('LOGS_TIMELINE');
export const LOGS_CRUD_SUBMIT = createAction('LOGS_CRUD_SUBMIT');

export const TOWERS_FETCH = createAction('TOWERS_FETCH');
export const TOWERS_SET_SORT = createAction('TOWERS_SET_SORT');
export const TOWERS_SET_VIEW = createAction('TOWERS_SET_VIEW');
export const TOWERS_SET_SEARCH = createAction('TOWERS_SET_SEARCH');
export const TOWERS_SET_FILTERS = createAction('TOWERS_SET_FILTERS');
export const TOWERS_CRUD_MODE = createAction('TOWERS_CRUD_MODE');
export const TOWERS_CRUD_SUBMIT = createAction('TOWERS_CRUD_SUBMIT');
export const TOWERS_RELAY_MODE = createAction('TOWERS_RELAY_MODE');
export const TOWERS_RELAY_CRUD_SUBMIT = createAction('TOWERS_RELAY_CRUD_SUBMIT');
export const TOWERS_CAMERA_MODE = createAction('TOWERS_CAMERA_MODE');
export const CAMERAS_FETCH = createAction('CAMERAS_FETCH');
export const CAMERAS_CRUD_SUBMIT = createAction('CAMERAS_CRUD_SUBMIT');

export const TEAMS_SET_SORT = createAction('TEAMS_SET_SORT');
export const TEAMS_SET_VIEW = createAction('TEAMS_SET_VIEW');
export const TEAMS_SET_SEARCH = createAction('TEAMS_SET_SEARCH');
export const TEAMS_SET_FILTERS = createAction('TEAMS_SET_FILTERS');
export const TEAMS_CRUD_MODE = createAction('TEAMS_CRUD_MODE');
export const TEAMS_FETCH = createAction('TEAMS_FETCH');
export const TEAMS_CRUD_SUBMIT = createAction('TEAMS_CRUD_SUBMIT');

export const ASSIGNEES_SET_SORT = createAction('ASSIGNEES_SET_SORT');
export const ASSIGNEES_SET_VIEW = createAction('ASSIGNEES_SET_VIEW');
export const ASSIGNEES_SET_SEARCH = createAction('ASSIGNEES_SET_SEARCH');
export const ASSIGNEES_SET_FILTERS = createAction('ASSIGNEES_SET_FILTERS');
export const ASSIGNEES_CRUD_MODE = createAction('ASSIGNEES_CRUD_MODE');
export const ASSIGNEES_FETCH = createAction('ASSIGNEES_FETCH');
export const ASSIGNEES_CRUD_SUBMIT = createAction('ASSIGNEES_CRUD_SUBMIT');
export const ASSIGNEES_ADD_ENTITY = createAction('ASSIGNEES_ADD_ENTITY');

export const ROLES_SET_SORT = createAction('ROLES_SET_SORT');
export const ROLES_SET_VIEW = createAction('ROLES_SET_VIEW');
export const ROLES_SET_SEARCH = createAction('ROLES_SET_SEARCH');
export const ROLES_SET_FILTERS = createAction('ROLES_SET_FILTERS');
export const ROLES_CRUD_MODE = createAction('ROLES_CRUD_MODE');
export const ROLES_FETCH = createAction('ROLES_FETCH');
export const ROLES_CRUD_SUBMIT = createAction('ROLES_CRUD_SUBMIT');

export const ACTIVITY_LOGS_SET_SORT = createAction('ACTIVITY_LOGS_SET_SORT');
export const ACTIVITY_LOGS_SET_VIEW = createAction('ACTIVITY_LOGS_SET_VIEW');
export const ACTIVITY_LOGS_SET_SEARCH = createAction('ACTIVITY_LOGS_SET_SEARCH');
export const ACTIVITY_LOGS_SET_FILTERS = createAction('ACTIVITY_LOGS_SET_FILTERS');
export const ACTIVITY_LOGS_CRUD_MODE = createAction('ACTIVITY_LOGS_CRUD_MODE');
export const ACTIVITY_LOGS_FETCH = createAction('ACTIVITY_LOGS_FETCH');
export const ACTIVITY_LOGS_CRUD_SUBMIT = createAction('ACTIVITY_LOGS_CRUD_SUBMIT');
export const ACTIVITY_LOGS_TIMELINE = createAction('ACTIVITY_LOGS_TIMELINE');

export const COMMENTS_FETCH = createAction('COMMENTS_FETCH');
export const COMMENTS_SUBMIT = createAction('COMMENTS_SUBMIT');

export const ENTIITY_DELETE = createAction('ENTIITY_DELETE');

/* USERS */
export const USERS_FETCH = createAction('USERS_FETCH');
export const USERS_SET_SORT = createAction('USERS_SET_SORT');
export const USERS_SET_VIEW = createAction('USERS_SET_VIEW');
export const USERS_SET_SEARCH = createAction('USERS_SET_SEARCH');
export const USERS_SET_FILTERS = createAction('USERS_SET_FILTERS');
export const USERS_CRUD_MODE = createAction('USERS_CRUD_MODE');
export const USERS_CRUD_SUBMIT = createAction('USERS_CRUD_SUBMIT');
export const USERS_CREATE = createAction('USERS_CREATE');
export const USERS_ACTIVE_TOGGLE = createAction('USERS_ACTIVE_TOGGLE');

/* METRICS */
export const METRICS_FETCH = createAction('METRICS_CONFIGS');
export const METRICS_ACTIONS_FETCH = createAction('METRICS_ACTIONS_FETCH');
export const METRICS_STATS = createAction('METRICS_STATS');
export const METRICS_FETCH_LATEST = createAction('METRICS_FETCH_LATEST');
export const METRICS_CHART_TIMELINE = createAction('METRICS_CHART_TIMELINE');
export const METRIC_HISTORY_FETCH = createAction('METRIC_HISTORY_FETCH');
export const METRICS_HISTORY_SET_FILTERS = createAction('METRICS_HISTORY_SET_FILTERS');

export const RELAY_SET = createAction('RELAY_SET');

export const RESTIFY_ENDPOINT_GET = createAction('RESTIFY_ENDPOINT_GET');

/* DEV */
export const REDUX_LOGGER = createAction('REDUX_LOGGER')

/* BASE */
export const SET_CURRENT_ROUTE_KEY = createAction('SET_CURRENT_ROUTE_KEY');

export * from "./api/actions";
export * from "./auth/actions";