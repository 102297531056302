import { all, put, select, fork, takeEvery } from 'redux-saga/effects';
import moment from "moment";
import { stardardRequest } from '../../utils';

import {
  METRICS_FETCH,
  METRICS_FETCH_LATEST,
  METRICS_STATS,
  METRICS_ACTIONS_FETCH,
  METRICS_CHART_TIMELINE,
  METRIC_HISTORY_FETCH,
  METRICS_HISTORY_SET_FILTERS,
  RELAY_SET
} from '../actions';

const apiBase = 'https://ims-dev.53dnorth.com//api'

export function* watchMetricsFetchRequest() {
  const action = METRICS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'get',
      url: `${apiBase}/v1/metrics/fetch`
    },
    requestOptionsFromPayload: ({ code }) => {
      return {
        data: { code }
      }
    },
    dataPath: 'metrics',
    dataDefault: [],
    beforeSuccess: (data, state, payload) => {
      return data.map(d => {
        d.metric_date = moment(d.metric_date).format('DD/MM/YYYY HH:mm:ss')
        return d
      })
    }
  }));
}

export function* watchMetricsLatestFetchRequest() {
  const action = METRICS_FETCH_LATEST
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'get',
    },
    requestOptionsFromPayload: ({ id }) => {
      return {
        url: `${apiBase}/v1/metrics/latest`,
        params: {
          id
        }
      }
    },
    dataPath: 'metrics',
    dataDefault: [],
    beforeSuccess: (data, state, payload) => {
      return data.map(d => {
        d.metric_date = moment(d.created_at).format('DD/MM/YYYY HH:mm:ss')
        return d
      })
    }
  }));
}

export function* watchMetricHistoryRequest() {
  const action = METRIC_HISTORY_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${apiBase}/v1/metrics/history`
    },
    requestOptionsFromPayload: ({ tower, metric, grouping }) => {
      return {
        data: { tower, metric, grouping: grouping||'latest' }
      }
    },
    dataPath: 'metrics',
    dataDefault: []
  }));
}

export function* watchMetricStatsRequest() {
  const action = METRICS_STATS
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${apiBase}/v1/metrics/stats`
    },
    requestOptionsFromPayload: ({ client }) => {
      return {
        data: { client }
      }
    },
    dataPath: 'stats',
    dataDefault: {}
  }));
}

export function* watchMetricActionsRequest() {
  const action = METRICS_ACTIONS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'get',
      url: `${apiBase}/v1/metrics/action-audit`
    },
    dataPath: 'actions',
    dataDefault: {}
  }));
}

export function* watchMetricsChartTimelineRequest() {
  const action = METRICS_CHART_TIMELINE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${apiBase}/v1/metrics/chart/timeline`
    },
    requestOptionsFromPayload: ({ tower, metric, interval }) => {
      return {
        data: { tower, metric, interval }
      }
    },
    dataPath: 'metrics',
    dataDefault: [],
    beforeSuccess: (data, state, payload) => {
      return { metric: payload.metric, data: data.map(d => {
        d.metric_date = `${d.metric_day.toString().padStart(2, '0')}/${d.metric_month.toString().padStart(2, '0')} ${d.metric_hour.toString().padStart(2, '0')}`
        return d
      }) }
    }
  }));
}

export function* watchRelaySetRequest() {
  const action = RELAY_SET
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    // authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${apiBase}/v1/controls/relay`,
      headers: {
        Authorization: `Bearer a66d956f-2d4d-475a-b2b1-7b6a8870c24a`
      }
    },
    requestOptionsFromPayload: ({ name, payload }) => {
      return {
        data: { name, payload }
      }
    },
    dataPath: 'metrics',
    dataDefault: []
  }));
}

export function* watchMetricHistoryFiltersSet() {
  const action = METRICS_HISTORY_SET_FILTERS
  yield takeEvery(action.REQUEST, function* ({ payload }) {
    const state = yield select();
    const { metrics: { grid_options: { filters } } }  = state
    yield put(METRIC_HISTORY_FETCH.requestAction({ ...payload, ...filters }))
  })
}

export default function* rootSaga() {
  yield all([
    fork(watchMetricsFetchRequest),
    fork(watchMetricStatsRequest),
    fork(watchMetricActionsRequest),
    fork(watchMetricsLatestFetchRequest),
    fork(watchMetricHistoryRequest),
    fork(watchMetricHistoryFiltersSet),
    fork(watchMetricsChartTimelineRequest),
    fork(watchRelaySetRequest),
  ]);
}