import { all, fork, put, takeEvery } from 'redux-saga/effects';
import moment from 'moment';
import { stardardRequest } from '../../utils';

import {
  TOWERS_FETCH,
  USERS_FETCH,
  USERS_CREATE,
  USERS_ACTIVE_TOGGLE,
  SITES_CRUD_SUBMIT,
  CLIENTS_CRUD_SUBMIT,
  CLIENTS_FETCH,
  TEAMS_FETCH,
  TEAMS_CRUD_SUBMIT,
  ASSIGNEES_FETCH,
  ASSIGNEES_CRUD_SUBMIT,
  ASSIGNEES_ADD_ENTITY,
  ROLES_FETCH,
  ROLES_CRUD_SUBMIT,
  INCIDENTS_FETCH,
  OVERVIEW_SET_DATE,
  INCIDENTS_SUMMARY_FETCH,
  INCIDENTS_STATS_FETCH,
  INCIDENTS_CRUD_SUBMIT,
  TASKS_FETCH,
  TASKS_MINE_FETCH,
  TASKS_CRUD_SUBMIT,
  LOGS_FETCH,
  LOGS_CRUD_SUBMIT,
  SITES_FETCH,
  ACTIVITY_LOGS_FETCH,
  ACTIVITY_LOGS_TIMELINE,
  ACTIVITY_LOGS_CRUD_SUBMIT,
  LOGS_TIMELINE,
  COMMENTS_FETCH,
  COMMENTS_SUBMIT,
  ENTIITY_DELETE
} from '../actions';

const apiBase = 'https://ims-dev.53dnorth.com/api'

export function* watchTowersFetchRequest() {
  const action = TOWERS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'get',
      url: `${apiBase}/v1/towers/fetch`
    },
    dataPath: 'result',
    dataDefault: [],
    beforeSuccess: (data, state, payload) => {
      data.sites = data.sites.map(d => {
        d.created_at = moment(d.created_at).format('DD/MM/YYYY HH:mm:ss')
        d.updated_at = moment(d.updated_at).format('DD/MM/YYYY HH:mm:ss')
        return d
      })
      data.clients = data.clients.map(d => {
        d.created_at = moment(d.created_at).format('DD/MM/YYYY HH:mm:ss')
        d.updated_at = moment(d.updated_at).format('DD/MM/YYYY HH:mm:ss')
        return d
      })
      data.towers = data.towers.map(d => {
        d.site.created_at = moment(d.site.created_at).format('DD/MM/YYYY HH:mm:ss')
        d.site.updated_at = moment(d.site.updated_at).format('DD/MM/YYYY HH:mm:ss')
        d.created_at = moment(d.created_at).format('DD/MM/YYYY HH:mm:ss')
        d.updated_at = moment(d.updated_at).format('DD/MM/YYYY HH:mm:ss')
        return d
      })
      return data
    }
  }));
}

export function* watchSitesFetchRequest() {
  const action = SITES_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${apiBase}/v1/sites/fetch`,
      }
    },
    dataPath: 'sites',
    dataDefault: null
  }));
}

export function* watchSitesCrudSubmitRequest() {
  const action = SITES_CRUD_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: payload.id ? 'patch' : 'post',
        url: `${apiBase}/v1/sites`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchClientsFetchRequest() {
  const action = CLIENTS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${apiBase}/v1/clients/fetch`,
      }
    },
    dataPath: 'clients',
    dataDefault: null
  }));
}

export function* watchClientsCrudSubmitRequest() {
  const action = CLIENTS_CRUD_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: payload.id ? 'patch' : 'post',
        url: `${apiBase}/v1/clients`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchTeamsFetchRequest() {
  const action = TEAMS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${apiBase}/v1/teams/fetch`,
      }
    },
    dataPath: 'teams',
    dataDefault: null
  }));
}

export function* watchTeamsCrudSubmitRequest() {
  const action = TEAMS_CRUD_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: payload.id ? 'patch' : 'post',
        url: `${apiBase}/v1/teams`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchAssigneesFetchRequest() {
  const action = ASSIGNEES_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${apiBase}/v1/assignees/fetch`,
      }
    },
    dataPath: 'assignees',
    dataDefault: null
  }));
}

export function* watchAssigneesCrudSubmitRequest() {
  const action = ASSIGNEES_CRUD_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: payload.id ? 'patch' : 'post',
        url: `${apiBase}/v1/assignees`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchAssigneesEntityRequest() {
  const action = ASSIGNEES_ADD_ENTITY
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      const { entity_type, entity_id, assignee_id } = payload
      return {
        method: 'patch',
        url: `${apiBase}/v1/assignees/assign/${entity_type}/${entity_id}/${assignee_id}`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchEntityDeleteRequest() {
  const action = ENTIITY_DELETE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      const { entity_type, entity_id } = payload
      return {
        method: 'delete',
        url: `${apiBase}/v1/${entity_type}s/${entity_id}`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchRolesFetchRequest() {
  const action = ROLES_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${apiBase}/v1/assignee-roles/fetch`,
      }
    },
    dataPath: 'assignees',
    dataDefault: null
  }));
}

export function* watchRolesCrudSubmitRequest() {
  const action = ROLES_CRUD_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: payload.id ? 'patch' : 'post',
        url: `${apiBase}/v1/assignee-roles`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchActivityFetchRequest() {
  const action = ACTIVITY_LOGS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${apiBase}/v1/activity/fetch`,
      }
    },
    dataPath: 'activity',
    dataDefault: null
  }));
}

export function* watchActivityTimelineFetchRequest() {
  const action = ACTIVITY_LOGS_TIMELINE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      const { towers: { grid_options: { activity_logs: { filters }, page } } } = state
      return {
        method: 'post',
        url: `${apiBase}/v1/activity/timeline`,
        data: {
          page,
          filters
        }
      }
    },
    dataPath: 'timeline',
    dataDefault: null
  }));
}

export function* watchActivityCrudSubmitRequest() {
  const action = ACTIVITY_LOGS_CRUD_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: payload.id ? 'patch' : 'post',
        url: `${apiBase}/v1/activity`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}


export function* watchIncidentsFetchRequest() {
  const action = INCIDENTS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${apiBase}/v1/incidents/fetch`,
      }
    },
    dataPath: 'incidents',
    dataDefault: null
  }));
}

export function* watchIncidentsOverviewDateFetchRequest() {
  const action = OVERVIEW_SET_DATE
  yield takeEvery(action.REQUEST, function* ({ payload }) {
    yield put(INCIDENTS_SUMMARY_FETCH.requestAction({}))
    yield put(INCIDENTS_STATS_FETCH.requestAction({}))
    yield put(TASKS_MINE_FETCH.requestAction({}))
  });
}

export function* watchIncidentsSummaryFetchRequest() {
  const action = INCIDENTS_SUMMARY_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, { towers: { overview_date } }) => {
      return {
        method: 'get',
        url: `${apiBase}/v1/incidents/summary?date=${overview_date ? overview_date.toISOString() : ''}`,
      }
    },
    dataPath: 'summary',
    dataDefault: null
  }));
}

export function* watchIncidentsStatsFetchRequest() {
  const action = INCIDENTS_STATS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, { towers: { overview_date } }) => {
      return {
        method: 'get',
        url: `${apiBase}/v1/incidents/stats?date=${overview_date ? overview_date.toISOString() : ''}`,
      }
    },
    dataPath: 'stats',
    dataDefault: null
  }));
}

export function* watchIncidentsCrudSubmitRequest() {
  const action = INCIDENTS_CRUD_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: payload.id ? 'patch' : 'post',
        url: `${apiBase}/v1/incidents`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchTasksFetchRequest() {
  const action = TASKS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${apiBase}/v1/tasks/fetch`,
      }
    },
    dataPath: 'tasks',
    dataDefault: null
  }));
}

export function* watchTasksMineFetchRequest() {
  const action = TASKS_MINE_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, { towers: { overview_date } }) => {
      return {
        method: 'get',
        url: `${apiBase}/v1/tasks/mine?date=${overview_date ? overview_date.toISOString() : ''}`,
      }
    },
    dataPath: 'tasks',
    dataDefault: null
  }));
}

export function* watchTasksCrudSubmitRequest() {
  const action = TASKS_CRUD_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: payload.id ? 'patch' : 'post',
        url: `${apiBase}/v1/tasks`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchLogsFetchRequest() {
  const action = LOGS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${apiBase}/v1/logs/fetch`,
      }
    },
    dataPath: 'logs',
    dataDefault: null
  }));
}

export function* watchLogsTimelineFetchRequest() {
  const action = LOGS_TIMELINE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      const { towers: { grid_options: { logs: { filters }, page } } } = state
      return {
        method: 'post',
        url: `${apiBase}/v1/logs/timeline`,
        data: {
          page,
          filters
        }
      }
    },
    dataPath: 'timeline',
    dataDefault: null
  }));
}

export function* watchLogsCrudSubmitRequest() {
  const action = LOGS_CRUD_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: payload.id ? 'patch' : 'post',
        url: `${apiBase}/v1/logs`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchUsersFetchRequest() {
  const action = USERS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'get',
      url: `${apiBase}/v1/auth/users/fetch`
    },
    dataPath: 'users',
    dataDefault: [],
  }));
}

export function* watchUsersCreateRequest() {
  const action = USERS_CREATE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${apiBase}/v1/auth/users/create`
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: payload
      }
    },
    dataPath: 'users',
    dataDefault: [],
  }));
}

export function* watchUsersActiveToggleRequest() {
  const action = USERS_ACTIVE_TOGGLE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${apiBase}/v1/auth/users/deleted/${payload.id}`,
        data: {
          ...payload,
          id: undefined
        }
      }
    },
    dataPath: 'user',
    dataDefault: [],
  }));
}

export function* watchCommentsFetchRequest() {
  const action = COMMENTS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${apiBase}/v1/comments/fetch`,
        data: payload
      }
    },
    dataPath: 'comments',
    dataDefault: [],
    beforeSuccess: (data) => {
      return data.map(d => ({
        id: d.id,
        name: d.user.email.split('@').shift(),
        src: null,
        message: d.description,
        date: moment(d.created_at).unix()
      }))
    }
  }));
}

export function* watchCommentsSubmitRequest() {
  const action = COMMENTS_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${apiBase}/v1/comments/add`,
        data: payload
      }
    },
    dataPath: 'comments',
    dataDefault: [],
  }));
}

export default function* rootSaga() {
  yield all([
    fork(watchSitesFetchRequest),
    fork(watchSitesCrudSubmitRequest),
    fork(watchClientsFetchRequest),
    fork(watchClientsCrudSubmitRequest),
    fork(watchTeamsFetchRequest),
    fork(watchTeamsCrudSubmitRequest),
    fork(watchAssigneesFetchRequest),
    fork(watchAssigneesCrudSubmitRequest),
    fork(watchAssigneesEntityRequest),
    fork(watchEntityDeleteRequest),
    fork(watchRolesFetchRequest),
    fork(watchRolesCrudSubmitRequest),
    fork(watchActivityFetchRequest),
    fork(watchActivityCrudSubmitRequest),
    fork(watchActivityTimelineFetchRequest),
    fork(watchIncidentsFetchRequest),
    fork(watchIncidentsOverviewDateFetchRequest),
    fork(watchIncidentsSummaryFetchRequest),
    fork(watchIncidentsStatsFetchRequest),
    fork(watchIncidentsCrudSubmitRequest),
    fork(watchTasksFetchRequest),
    fork(watchTasksMineFetchRequest),
    fork(watchTasksCrudSubmitRequest),
    fork(watchLogsFetchRequest),
    fork(watchLogsTimelineFetchRequest),
    fork(watchLogsCrudSubmitRequest),
    fork(watchCommentsFetchRequest),
    fork(watchCommentsSubmitRequest),
    fork(watchUsersFetchRequest),
    fork(watchUsersCreateRequest),
    fork(watchUsersActiveToggleRequest)
  ]);
}