import { REHYDRATE } from 'redux-persist';
import {
  TASKS_BOARD_OPEN_DIALOG,
  TASKS_BOARD_CLOSE_DIALOG,
  TASKS_BOARD_UPDATE_DIALOG_VIEW,
  TASKS_BOARD_SET_SELECTED_TASKS_ID,
  TASKS_BOARD_SET_SELECTED_LOGS_ID,
  TASKS_BOARD_SET_SELECTED_BOARD,
  TASKS_BOARD_SET_SELECTED_TAB,
  TASKS_BOARD_SET_INCIDENT,
  TASKS_BOARD_UPDATE_ORDERED,
  TASKS_BOARD_UPDATE_COLUMNS,
  TASKS_BOARD_UPDATE_BOARD_MEMBERS,
  TASKS_BOARD_BOARDS_FETCH,
  TASKS_BOARD_MEMBERS_FETCH,
  FILE_UPLOAD_REQUEST
} from '../actions';

const INIT_STATE = {
  dialogOpen: false,
  dialogView: '',
  entityId: null,
  board: '',
  selectedTab: 'All',

  loading: false,
  columns: {},
  ordered: [],
  boardMembers: [],
  allMembers: [],

  priorities: [{
    value: 'low',
    label: 'Low'
  }, {
    value: 'medium',
    label: 'Medium'
  }, {
    value: 'high',
    label: 'High'
  }],
  
  statuses: [{
    value: 'todo',
    label: 'To Do'
  }, {
    value: 'in-progress',
    label: 'In Progress'
  }, {
    value: 'submitted',
    label: 'Submitted'
  }, {
    value: 'completed',
    label: 'Completed'
  }],

  files_uploading: []
};

const handler = (state = INIT_STATE, action) => {
  // console.log(action.type, action.payload)
  switch (action.type) {
    case TASKS_BOARD_OPEN_DIALOG.REQUEST: {
      return {
        ...state,
        dialogOpen: true
      }
    }
    case TASKS_BOARD_CLOSE_DIALOG.REQUEST: {
      return {
        ...state,
        dialogOpen: false,
        entityId: '',
        board: '',
        dialogView: '',
      }
    }
    case TASKS_BOARD_UPDATE_DIALOG_VIEW.REQUEST: {
      return {
        ...state,
        dialogView: action.payload
      }
    }
    case TASKS_BOARD_SET_SELECTED_TASKS_ID.REQUEST:
    case TASKS_BOARD_SET_SELECTED_LOGS_ID.REQUEST: {
      return {
        ...state,
        entityId: action.payload
      }
    }
    case TASKS_BOARD_SET_SELECTED_BOARD.REQUEST: {
      return {
        ...state,
        board: action.payload
      }
    }
    case TASKS_BOARD_SET_SELECTED_TAB.REQUEST: {
      return {
        ...state,
        selectedTab: action.payload
      }
    }
    case TASKS_BOARD_SET_INCIDENT.REQUEST: {
      return {
        ...state,
        incidentId: action.payload
      }
    }
    case TASKS_BOARD_UPDATE_ORDERED.REQUEST: {
      return {
        ...state,
        ordered: action.payload
      }
    }
    case TASKS_BOARD_UPDATE_COLUMNS.REQUEST: {
      return {
        ...state,
        columns: action.payload
      }
    }
    case TASKS_BOARD_UPDATE_BOARD_MEMBERS.REQUEST: {
      return {
        ...state,
        boardMembers: action.payload
      }
    }
    case TASKS_BOARD_BOARDS_FETCH.SUCCESS: {
      state.columns = action.payload
      state.ordered = Object.keys(action.payload)
      return {
        ...state,
        columns: action.payload,
        ordered: Object.keys(action.payload)
      }
    }
    case TASKS_BOARD_MEMBERS_FETCH.SUCCESS: {
      return {
        ...state,
        boardMembers: action.payload.participantMembers,
        allMembers: action.payload.allMembers
      }
    }
    case FILE_UPLOAD_REQUEST.REQUEST:
    case FILE_UPLOAD_REQUEST.SUCCESS:
    case FILE_UPLOAD_REQUEST.ERROR: {
      let files_uploading = [...state.files_uploading]
      if (action.type.endsWith('_REQUEST')) {
        const { file } = action.payload
        if (files_uploading.indexOf(file.name) === -1)  {
          files_uploading.push(file.name)
        }
      } else {
        const { original_name } = action.payload
        files_uploading = files_uploading.filter(f => f !== original_name)
      }
      return {
        ...state,
        files_uploading
      }
    }
    case REHYDRATE: {
      return INIT_STATE
    }
    default: return { ...state };
  }
}

export default handler
