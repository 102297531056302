import { initializeApp } from "firebase/app";
import {
  getAuth,
} from 'firebase/auth'
import axios from 'axios'
axios.defaults.headers.common['x-auth-strategy'] = 'firebase';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD-M1s3D9tkRMDvPO4_pjjVjhNpTs84koI",
  authDomain: "incident-management-bc90f.firebaseapp.com",
  projectId: "incident-management-bc90f",
  storageBucket: "incident-management-bc90f.appspot.com",
  messagingSenderId: "139329834314",
  appId: "1:139329834314:web:cc8011e2459eed7cf185b3",
  measurementId: "G-QN5KWTKZ7Z"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = () => {
  return getAuth(app)
}

export default auth