import { REHYDRATE } from 'redux-persist';
import _ from 'lodash';
// import {
//   SITES_SET_SEARCH,
//   SITES_SET_SORT,
//   SITES_SET_VIEW,
//   SITES_SET_FILTERS,
//   SITES_FETCH,
//   SITES_CRUD_MODE,
//   CLIENTS_SET_SEARCH,
//   CLIENTS_SET_SORT,
//   CLIENTS_SET_VIEW,
//   CLIENTS_SET_FILTERS,
//   CLIENTS_FETCH,
//   CLIENTS_CRUD_MODE,
//   INCIDENTS_FETCH,
//   INCIDENTS_CRUD_MODE,
//   TASKS_FETCH,
//   TASKS_CRUD_MODE,
//   LOGS_FETCH,
//   LOGS_CRUD_MODE,
//   TEAMS_FETCH,
//   TEAMS_CRUD_MODE,
//   ASSIGNEES_FETCH,
//   ASSIGNEES_CRUD_MODE,
//   USERS_FETCH,
//   USERS_SET_SEARCH,
//   USERS_SET_SORT,
//   USERS_SET_VIEW,
//   USERS_SET_FILTERS,
//   USERS_CRUD_MODE,
// } from '../actions';

import {
  ACTIVITY_LOGS_TIMELINE,
  LOGS_TIMELINE,
  OVERVIEW_SET_DATE
} from '../actions';

const standardListEls = [
  'sites', 
  'clients', 
  'incidents', 
  'incidents_summary',
  'incidents_stats',
  'tasks', 
  'tasks_mine', 
  'logs', 
  'teams', 
  'assignees', 
  'roles',
  'activity_logs',
  'users',
]
const standardGridOptions = {
  crud_modal: null,
  crud_data: null,
  view: 'grid',
  sort: 'asc',
  search: null,
  page: 1,
  total_pages: null,
  filters: {}
}

const INIT_STATE = {
  grid_options: {},
  logs_timeline: [],
  overview_date: null
};
for (let el of standardListEls) {
  INIT_STATE[el] = []
  INIT_STATE.grid_options[el] = _.cloneDeep(standardGridOptions)
}

const getKey = (action) => {
  let key = null
  let prefix = null
  const allKeys = ['relays', 'towers', 'clients', 'sites', 'cameras']
  if (action.type.indexOf('CLIENTS_') === 0) {
    key = 'clients'
    prefix = 'CLIENTS_'
  } else if (action.type.indexOf('SITES_') === 0) {
    key = 'sites'
    prefix = 'SITES_'
  } else if (action.type.indexOf('INCIDENTS_SUMMARY_') === 0) {
    key = 'incidents_summary'
    prefix = 'INCIDENTS_SUMMARY_'
  } else if (action.type.indexOf('INCIDENTS_STATS_') === 0) {
    key = 'incidents_stats'
    prefix = 'INCIDENTS_STATS_'
  } else if (action.type.indexOf('INCIDENTS_') === 0) {
    key = 'incidents'
    prefix = 'INCIDENTS_'
  } else if (action.type.indexOf('TASKS_MINE_') === 0) {
    key = 'tasks_mine'
    prefix = 'TASKS_MINE_'
   }else if (action.type.indexOf('TASKS_') === 0) {
    key = 'tasks'
    prefix = 'TASKS_'
  } else if (action.type.indexOf('LOGS_') === 0) {
    key = 'logs'
    prefix = 'LOGS_'
  } else if (action.type.indexOf('TEAMS_') === 0) {
    key = 'teams'
    prefix = 'TEAMS_'
  } else if (action.type.indexOf('USERS_') === 0) {
    key = 'users'
    prefix = 'USERS_'
  } else if (action.type.indexOf('ASSIGNEES_') === 0) {
    key = 'assignees'
    prefix = 'ASSIGNEES_'
  } else if (action.type.indexOf('ROLES_') === 0) {
    key = 'roles'
    prefix = 'ROLES_'
  } else if (action.type.indexOf('ACTIVITY_LOGS_') === 0) {
    key = 'activity_logs'
    prefix = 'ACTIVITY_LOGS_'
  }
  return { key, prefix, remaining: allKeys.filter(a => a !== key) }
}

const handler = (state = INIT_STATE, action) => {
  const { key, remaining } = getKey(action)
  switch (action.type) {
    case ACTIVITY_LOGS_TIMELINE.REQUEST: {
      const { filters, page } = action.payload
      const { page: curPage, filters: curFilters } = state.grid_options[key]
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          page: page ? page : curPage,
          filters: filters ? filters : curFilters,
        }
      } }
    }
    case ACTIVITY_LOGS_TIMELINE.SUCCESS: {
      return { ...state, activity_logs: action.payload }
    }
    case LOGS_TIMELINE.REQUEST: {
      const { filters, page } = action.payload
      const { page: curPage, filters: curFilters } = state.grid_options[key]
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          page: page ? page : curPage,
          filters: filters ? filters : curFilters,
        }
      } }
    }
    case LOGS_TIMELINE.SUCCESS: {
      return { ...state, logs_timeline: action.payload }
    }
    case OVERVIEW_SET_DATE.REQUEST: {
      return { ...state, overview_date: action.payload.overview_date }
    }
    case REHYDRATE: {
      return INIT_STATE
    }
    default:
      break
  }

  if (standardListEls.indexOf(key) > -1) {
    if (action.type.endsWith('_FETCH_REQUEST')) {
      return { ...state, [key]: [] }
    } else if (action.type.endsWith('_CRUD_MODE_REQUEST')) {
      const { mode, data } = action.payload
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          crud_modal: mode,
          crud_data: data||null
        }
      } }
    } else if (action.type.endsWith('_SET_SEARCH_REQUEST')) {
      // const { key } = getKey(action)
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          search: action.payload
        }
      } }
    } else if (action.type.endsWith('_SET_SORT_REQUEST')) {
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          sort: action.payload
        }
      } }
    } else if (action.type.endsWith('_SET_VIEW_REQUEST')) {
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          view: action.payload
        }
      } }
    } else if (action.type.endsWith('_SET_FILTERS_REQUEST')) {
      const [remKey1, remKey2, remKey3] = remaining
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          filters: action.payload
        },
        [remKey1]: {
          ...state.grid_options[key],
          filters: {}
        },
        [remKey2]: {
          ...state.grid_options[key],
          filters: {}
        },
        [remKey3]: {
          ...state.grid_options[key],
          filters: {}
        },
      } }
    } else if (action.type.endsWith('_FETCH_SUCCESS')) {
      return { ...state, [key]: action.payload }
    }
  }

  return { ...state }
}

export default handler
