import _ from 'lodash';
import { all, fork, takeEvery } from 'redux-saga/effects';
import { stardardRequest } from '../../utils';

import {
  TASKS_BOARD_BOARDS_FETCH,
  TASKS_UPDATE_STATUS,
  TASKS_UPDATE_PRIORITY,
  FILE_UPLOAD_REQUEST,
  TASKS_FILE_DELETE
} from '../actions';

const apiBase = 'https://ims-dev.53dnorth.com/api'

export function* watchTaskBoardsFetchRequest() {
  const action = TASKS_BOARD_BOARDS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
    },
    requestOptionsFromPayload: (payload, state) => {
      return {
        url: `${apiBase}/v1/tasks/fetch/board`,
        data: payload
      }
    },
    dataPath: 'tasks',
    dataDefault: []
  }));
}

export function* watchTaskUpdateStatusRequest() {
  const action = TASKS_UPDATE_STATUS
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'patch',
      url: `${apiBase}/v1/tasks/status/update`
    },
    requestOptionsFromPayload: (payload, state) => {
      let { id, status } = payload
      status = _.find(state.taskboard.statuses, s => s.label === status).value
      return {
        data: {
          id,
          status
        }
      }
    },
    dataPath: 'tasks',
    dataDefault: []
  }));
}

export function* watchTaskUpdatePriorityRequest() {
  const action = TASKS_UPDATE_PRIORITY
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'patch',
      url: `${apiBase}/v1/tasks/priority/update`
    },
    requestOptionsFromPayload: (payload, state) => {
      let { entity_id, priority } = payload
      const mapping = {'Low priority': 'low', 'Medium priority': 'medium', 'High priority': 'high'}
      return {
        data: {
          id: entity_id,
          priority: mapping[priority]
        }
      }
    },
    dataPath: 'tasks',
    dataDefault: []
  }));
}

export function* watchFileUploadRequest() {
  const action = FILE_UPLOAD_REQUEST
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${apiBase}/v1/files/upload`,
      headers: {
        "Content-Type": "multipart/form-data",
      }
    },
    requestOptionsFromPayload: (payload, state) => {
      let { file } = payload
      let formData = new FormData();
      formData.append("file", file);
      return {
        data: formData
      }
    },
    dataPath: 'file',
    dataDefault: []
  }));
}

export function* watchFileDeleteRequest() {
  const action = TASKS_FILE_DELETE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'delete'
    },
    requestOptionsFromPayload: ({ id }, state) => {
      return {
        url: `${apiBase}/v1/files/${id}`,
      }
    },
    dataPath: 'file',
    dataDefault: []
  }));
}


export default function* rootSaga() {
  yield all([
    fork(watchTaskBoardsFetchRequest),
    fork(watchTaskUpdateStatusRequest),
    fork(watchTaskUpdatePriorityRequest),
    fork(watchFileUploadRequest),
    fork(watchFileDeleteRequest),
  ]);
}