// api/selectors.js
// https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6
import _ from 'lodash';

export const createLoadingSelector = (actions) => (state) => {
  // returns true only when all actions is not loading
  // console.log(state.api)
  return _(actions)
    .some((action) =>  _.get(state, `api.loading.${action}`)) 
};

export const createErrorMessageSelector = (actions) => (state) => {
  // returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first error
  return _(actions)
    .map((action) => _.get(state, `api.error.${action}`))
    .compact()
    .first() || '';
};