import React, { useEffect, useRef, useState } from 'react'
import { VscFilePdf, VscFileZip, VscFile } from 'react-icons/vsc'
import { useDispatch, useSelector } from 'react-redux'
import { FILE_UPLOAD_REQUEST } from 'redux/actions'
import { createErrorMessageSelector, createLoadingSelector } from 'redux/api/selectors'
import CloseButton from '../CloseButton'
import Loading from '../../shared/Loading'

const BYTE = 1000
const getKB = (bytes) => Math.round(bytes / BYTE)

const FileIcon = ({children}) => {
	return <span className="text-4xl">{children}</span>
}

const FileItem = props => {

	const dispatch = useDispatch()

	const [uploaded, setUploaded] = useState(0)
	const [error, setError] = useState(false)

	const { file, children, index, removeFile, onUpload } = props
	const { type, name, size } = file

	useEffect(() => {
		if (!uploaded) {
			setUploaded(1)
			dispatch(FILE_UPLOAD_REQUEST.requestAction({
				file,
				successCb: (data) => {
					if (onUpload) {
						onUpload(data.new_name)
					}
					setUploaded(2)
				},
				errorCb: (error) => {
					setUploaded(3)
					setError(error)
				}
			}))
		}
	}, [file, index, uploaded, dispatch, onUpload])

	const renderThumbnail = () => {
		const isImageFile = type.split('/')[0] === 'image'

		if(isImageFile) {
			return <img 
				className="upload-file-image" 
				src={URL.createObjectURL(file)} 
				alt={`file preview ${name}`}
			/>
		}

		if(type === 'application/zip') {
			return <FileIcon><VscFileZip /></FileIcon>
		}

		if(type === 'application/pdf') {
			return <FileIcon><VscFilePdf /></FileIcon>
		}

		return <FileIcon><VscFile /></FileIcon>
	}

	return (
		<div className="upload-file">
			<div className="flex">
				<div className="upload-file-thumbnail">
					{renderThumbnail()}
				</div>
				<div className="upload-file-info">
					<h6 className="upload-file-name">{name}</h6>
					<span className="upload-file-size">{getKB(size)} kb</span>
					{error}
				</div>
			</div>
			<Loading loading={uploaded===1} />
			<CloseButton onClick={() => removeFile(index)} className="upload-file-remove" />
			{children}
		</div>
	)
}

export default React.memo(FileItem)
